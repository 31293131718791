import {inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {IPagination} from '../../types/IPagination';
import {buildQuery} from '../../../helpers';
import {map, switchMap, tap} from 'rxjs/operators';
import {BoomerHttpClient} from '../../services/boomer-http-client';

type SoaType = 'DOCUSIGN' | 'DIGITAL';
export type SoaStatus = 'SENT' | 'RESENT' | 'SIGNED' | 'COMPLETED' | 'CANCELED';

export interface Soa {
  uuid: string;
  type: SoaType;
  updatedAt: Date;
  status: SoaStatus;
  name: string;
  createdAt: Date;
  applicantUser: {
    uuid: string;
    firstName: string;
    lastName: string;
    email: string;
  };
}

export type GetAllSoasResponse = {
  totalLength: number;
  items: Array<Soa>;
};

export interface StartSoaRequest {
  plans: string;
  to: string;
  cc: string;
}

export interface ResendSoaRequest {
  uuid: string;
  plans: string;
  to: string;
  cc: string;
}

export interface StartSoaResponse {
  soaUUID: string;
}

export interface CreateSoaData {
  to: string;
  cc: string;
}

export interface ViewSoaData {
  applicantEmail: string;
  applicantName: string;
  applicantSignatureURL: string;
  dentalVisionHearingProducts: boolean;
  hospitalIndemnityProducts: boolean;
  id: string;
  medicareAdvantage: boolean;
  medicareSupplement: boolean;
  prescriptionDrugs: boolean;
  status: SoaStatus;
}

export type GetAllSoasRequestSortField =
  'NAME'
  | 'STATUS'
  | 'CREATED_AT'
  | 'UPDATED_AT'
  | 'FIRST_NAME'
  | 'LAST_NAME'
  | 'EMAIL';

export interface GetAllSoasRequest extends IPagination<GetAllSoasRequestSortField> {
  fistName?: string;
  lastName?: string;
  email?: string;
  status?: Array<string>;
  createdFrom?: Date;
  createdTo?: Date;
  updatedFrom?: Date;
  updatedTo?: Date;
}

export interface SignFormRequestResponse {
  beneficiaryAddress: string;
  beneficiaryCity: string;
  beneficiaryEmail: string;
  beneficiaryFirstName: string;
  beneficiaryLastName: string;
  beneficiaryPhone: string;
  beneficiaryState: string;
  beneficiaryZipCode: string;
  dentalVisionHearingProducts: boolean;
  hospitalIndemnityProducts: boolean;
  medicareAdvantage: boolean;
  medicareSupplement: boolean;
  prescriptionDrugs: boolean;
  signature: string;
  representativeFirstName: string;
  representativeLastName: string;
  representativeRelationship: string;
}

export type AnonymousSoaSignResponse = {
  userExists: boolean;
};

export interface AnonymousSignFormRequestResponse {
  prescriptionDrugs: boolean;
  dentalVisionHearingProducts: boolean;
  hospitalIndemnityProducts: boolean;
  medicareAdvantage: boolean;
  medicareSupplement: boolean;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  representativeFirstName: string;
  representativeLastName: string;
  representativeRelationship: string;
  address: {
    addressLine1: string,
    addressLine2: string,
    city: string,
    countyName: string,
    fipsCode: string,
    state: string,
    zipCode: string,
  };
  signature: string;
  captchaToken: string;
}

export interface CompleteSoaRequest {
  applicantSignatureURL: string;
  agentId: any;
  medicareAdvantage: boolean;
  agentName: any;
  initialMethodOfContact: any;
  applicantName: string;
  applicantEmail: string;
  prescriptionDrugs: boolean;
  dentalVisionHearingProducts: boolean;
  dateAppointmentCompleted: any;
  hospitalIndemnityProducts: boolean;
  plansDiscussed: any;
  medicareSupplement: boolean;
  id: string;
  status: SoaStatus;
}

@Injectable({
  providedIn: 'root'
})
export class SoaService {
  private http = inject(BoomerHttpClient);

  private refreshSoARequests = new BehaviorSubject(false);
  private refreshSoARequests$ = this.refreshSoARequests.asObservable();

  startSoa(applicantId: string, data: StartSoaRequest, type: SoaType): Observable<StartSoaResponse> {
    return this.http.post<StartSoaResponse>(`/soa/applicant/${applicantId}`, data, {params: {type}})
      .pipe(
        tap(() => this.refreshSoARequests.next(true))
      );
  }

  getCreateSoaData(applicantId: string): Observable<CreateSoaData> {
    return this.http.get<CreateSoaData>(`/soa/applicant/${applicantId}`);
  }

  getAllSoas(applicantId: string | undefined, pagination: GetAllSoasRequest): Observable<GetAllSoasResponse> {
    const url = applicantId === undefined
      ? `/soa/list`
      : `/soa/list/${applicantId}`;

    return this.refreshSoARequests$.pipe(
      switchMap(() =>
        this.http.get<Array<Soa>>(url, {params: buildQuery(pagination), observe: 'response'})
      ),
      map(res => {
        return {
          totalLength: +res.headers.get('x-total-elements'),
          items: res.body
        };
      })
    );
  }

  resend(data: ResendSoaRequest) {
    return this.http.post(`/soa/${data.uuid}/resend`, data)
      .pipe(
        tap(() => this.refreshSoARequests.next(true))
      );
  }

  cancel(uuid: string) {
    return this.http.post(`/soa/${uuid}/cancel`, {})
      .pipe(
        tap(() => this.refreshSoARequests.next(true))
      );
  }

  getApplicantSoaFormData(applicantId: string): Observable<SignFormRequestResponse> {
    return this.http.get<SignFormRequestResponse>(`/soa/applicant/${applicantId}/sign`);
  }

  signApplicantSoa(applicantId: string, data: SignFormRequestResponse) {
    return this.http.post(`/soa/applicant/${applicantId}/sign`, data);
  }

  getSignFormData(uuid: string): Observable<SignFormRequestResponse> {
    return this.http.get<SignFormRequestResponse>(`/soa/${uuid}`);
  }

  signForm(uuid: string, data: SignFormRequestResponse) {
    return this.http.post(`/soa/${uuid}/sign/applicant`, data);
  }

  signCurrentApplicantForm(data: SignFormRequestResponse) {
    return this.http.post(`/soa/applicant/sign`, data);
  }

  anonymousSign(data: AnonymousSignFormRequestResponse) {
    return this.http.post<AnonymousSoaSignResponse>(`/soa/anonymous`, data);
  }

  viewSoa(uuid: string) {
    return this.http.get<ViewSoaData>(`/soa/${uuid}/view`);
  }

  complete(soaId: string, data: CompleteSoaRequest) {
    return this.http.post(`/soa/${soaId}/complete`, data)
      .pipe(
        tap(() => this.refreshSoARequests.next(true))
      );
  }

  getPDF(uuid: string) {
    return this.http.get(`/soa/${uuid}/pdf`, {responseType: 'blob'})
      .pipe(
        map(data => new Blob([data], {type: 'application/pdf'}))
      );
  }

  transfer(uuid: string, targetApplicantUuid: string) {
    return this.http.patch(
      `/soa/${uuid}/transfer`,
      {applicantUUID: targetApplicantUuid}
    ).pipe(
      tap(() => this.refreshSoARequests.next(true))
    );
  }
}
